<template>
  <div class="bitts-drawer-deux">
    <ADrawer
      :open="visible"
      :width="width"
      :keyboard="closeWithKeyboard"
      placement="right"
      :mask-closable="true"
      :closable="false"
      :content-wrapper-style="{ margin: '0px' }"
      :body-style="{ padding: '0px' }"
      :destroy-on-close="destroyOnClose"
      class="bitts-drawer-deux"
      @close="onClose"
    >
      <BittsLoading :is-loading="loading">
        <div class="bitts-drawer-deux-content" data-testid="drawer-content">
          <header
            :class="{
              'border-b border-neutral-border': hasHeadingBorder,
              'fullstory-hide dd-privacy-mask': hideFromSessionReplay,
            }"
          >
            <slot name="headerAvatar"></slot>
            <div class="grow">
              <slot name="headerTitle" :title="title">
                <h2 data-testid="drawer-title" class="text-lg font-bold">{{
                  title
                }}</h2>
              </slot>
            </div>
            <BittsButton
              type="neutral"
              variant="ghost"
              size="medium"
              class="text-neutral-text-placeholder"
              data-testid="drawer-close-button"
              :center-icon="['fas', 'x']"
              @click="onClose"
            />
          </header>
          <!-- margin top to accommodate fixed header -->
          <div class="flex-1 mt-[72px]">
            <slot name="content" />
          </div>
          <footer
            v-if="showFooter"
            class="p-16 z-30 sticky flex gap-16 bottom-0 bg-white border-t border-neutral-border"
          >
            <slot name="footer">
              <slot name="secondaryButton">
                <BittsButton
                  v-if="!$slots.secondaryButton && secondaryButtonText"
                  :text="secondaryButtonText"
                  :type="secondaryButtonType"
                  :loading="isSaving"
                  variant="ghost"
                  class="w-1/2"
                  data-testid="drawer-secondary-button"
                  @click="(e) => emit('action:secondary', e)"
                />
              </slot>
              <slot name="primaryButton">
                <BittsButton
                  :text="primaryButtonText"
                  type="primary"
                  :loading="isSaving"
                  :disabled="isSavingDisabled"
                  data-testid="drawer-primary-button"
                  :class="hasSecondaryButton ? 'w-1/2' : 'w-full'"
                  @click="(e) => emit('action:primary', e)"
                />
              </slot>
            </slot>
          </footer>
        </div>
      </BittsLoading>
    </ADrawer>
  </div>
</template>

<script setup lang="ts">
import { Drawer as ADrawer } from 'ant-design-vue';
import { computed } from 'vue';
import type { VNode } from 'vue';

import BittsButton, { BittsButtonType } from '../BittsButton/BittsButton.vue';
import BittsLoading from '../BittsLoading/BittsLoading.vue';

export type Props = {
  destroyOnClose?: boolean;
  hideFromSessionReplay?: boolean;
  closeWithKeyboard?: boolean;
  showFooter?: boolean;
  title?: string;
  loading?: boolean;
  hasHeadingBorder?: boolean;
  visible?: boolean;
  width?: string;
  primaryButtonText?: string;
  isSaving?: boolean;
  isSavingDisabled?: boolean;
  secondaryButtonType?: Extract<BittsButtonType, 'danger' | 'neutral'>;
  secondaryButtonText?: string;
};

const props = withDefaults(defineProps<Props>(), {
  destroyOnClose: false,
  hideFromSessionReplay: false,
  closeWithKeyboard: true,
  showFooter: true,
  title: '',
  loading: false,
  hasHeadingBorder: true,
  visible: false,
  width: '600',
  primaryButtonText: 'Save',
  isSaving: false,
  isSavingDisabled: false,
  secondaryButtonType: 'neutral',
  secondaryButtonText: '',
});

const emit = defineEmits<{
  closed: [hasChanged: PointerEvent | KeyboardEvent];
  'action:primary': [e: KeyboardEvent];
  'action:secondary': [e: KeyboardEvent];
}>();

const slots = defineSlots<{
  headerAvatar(): SlotContent;
  headerTitle(props: { title: string }): SlotContent;
  content(): SlotContent;
  footer(): SlotContent;
  primaryButton(): SlotContent;
  secondaryButton(): SlotContent;
}>();

type SlotContent = VNode | string;

export type SlotType = keyof typeof slots;

const hasSecondaryButton = computed(
  () => !!props.secondaryButtonText || !!slots.secondaryButton,
);
function onClose(e: PointerEvent | KeyboardEvent) {
  emit('closed', e);
}
</script>

<style scoped lang="pcss">
header {
  @apply flex items-center p-16 gap-8 absolute top-0 bg-white z-10 w-full;
}

.bitts-drawer-deux-content {
  @apply flex flex-col justify-between h-full;
}
</style>

<style lang="pcss">
/* The drawer is a teleported element, we can't target this with Vue's :deep() helper */
.ant-drawer-content.bitts-drawer-deux {
  @apply border-neutral-background-disabled rounded-none;
}
</style>
